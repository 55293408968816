import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { TypeColor } from '@/utils/prismic';
import {
  breakpoints,
  colors,
  Container,
  convertPxToRem,
  paragraphs,
  radius,
  shadows
} from '@proprioo/salatim';

import { AppLayout } from '../App.styles';

export const LogoContainer = styled.div<{
  isOpen: boolean;
}>`
  a {
    display: block;
    color: currentColor;
  }

  svg {
    width: ${convertPxToRem(120)};
    height: ${convertPxToRem(44)};

    @media (min-width: ${breakpoints.base}px) {
      width: ${convertPxToRem(200)};
      height: ${convertPxToRem(88)};
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: none;
    `}
`;

export const Layout = styled.div<{
  isTransparent: boolean;
  isOpen: boolean;
  isScrolled: boolean;
  theme?: string;
}>`
  z-index: 11;
  color: white;
  position: absolute;
  left: 0;
  width: 100%;

  @media (min-width: ${breakpoints.base}px) {
    transition: 0.4s background ease-out;
  }

  ${Container} {
    display: flex;
    align-items: center;
  }

  button {
    pointer-events: all;
  }

  ${({ theme }) =>
    theme === TypeColor.BLUE &&
    css`
      color: ${colors.dark.base};
    `};

  ${({ isTransparent, isScrolled }) =>
    isTransparent && !isScrolled
      ? css`
          background-color: transparent;
          box-shadow: none;

          ${LogoContainer} {
            a {
              color: currentColor;
            }
          }
        `
      : css`
          box-shadow: ${shadows.level2};
          background: white;
          color: ${colors.dark.base};

          ${LogoContainer} {
            a {
              color: ${colors.dark.base};
            }
          }
        `}

  ${({ isOpen }) =>
    isOpen &&
    css`
      background: none;
      box-shadow: none;
      pointer-events: none;
    `};

  ${({ isScrolled }) =>
    isScrolled &&
    css`
      position: fixed;
      width: 100%;
      top: 0;
    `}
`;

const ItemBase = css`
  color: ${colors.dark.base};
  display: block;
  cursor: pointer;
  ${paragraphs.body2}

  & + & {
    margin-top: ${convertPxToRem(4)};
  }
`;

export const ItemSpan = styled.span`
  ${ItemBase}
`;

export const Label = styled.div`
  color: currentColor;
  display: flex;
  align-items: center;
  white-space: nowrap;

  svg {
    transform: rotate(90deg);
    transition: transform 0.2s;
    width: ${convertPxToRem(32)};
    height: ${convertPxToRem(32)};
  }

  a {
    color: currentColor;
  }
`;

export const SimpleLink = styled.a`
  color: currentColor;
  display: flex;
  align-items: center;
  padding: 0 ${convertPxToRem(8)};

  &:hover {
    color: ${colors.terracota.base};
  }
`;

export const DropdownItems = styled.div`
  display: flex;
  position: absolute;
  top: ${convertPxToRem(56)};
  left: 0;
  background: white;
  padding: ${convertPxToRem(20)};
  flex-direction: column;
  z-index: 4;
  border: ${convertPxToRem(1)} solid ${colors.grey.base10};
  box-shadow: ${shadows.level3};
  border-radius: ${radius.big};
  min-width: ${convertPxToRem(250)};
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, ${convertPxToRem(16)}, 0);
  transition: 0.2s all 0.2s ease-out;

  a {
    border-radius: ${radius.biggest};
    padding: ${convertPxToRem(10)} ${convertPxToRem(16)};
    transition: 0.2s background ease-out;
    white-space: nowrap;

    &:hover {
      background: ${colors.pink.base};
    }
  }
`;

export const Dropdown = styled.div<{ isCurrent: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  height: ${convertPxToRem(48)};
  padding: 0 ${convertPxToRem(12)};
  border-radius: ${convertPxToRem(8)};
  transition: 0.2s background-color linear;

  &:hover {
    background: ${colors.pink.base};

    ${DropdownItems} {
      opacity: 1;
      visibility: visible;
      transform: translate3d(0, 0, 0);
    }

    ${Label} svg {
      transform: rotate(-90deg);
    }
  }
`;

export const DesktopLayout = styled(Layout)`
  padding: 0;

  ${Container} {
    @media (max-width: ${breakpoints.base - 1}px) {
      display: none;
    }
  }
`;

export const DropdownContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-evenly;
`;

export const LinksContainer = styled.div`
  text-align: right;
  display: flex;

  a + a {
    margin-left: ${convertPxToRem(8)};
  }

  a {
    white-space: nowrap;
  }

  a:last-child {
    @media (min-width: ${breakpoints.base}px) {
      display: none;
    }

    @media (min-width: ${convertPxToRem(1320)}) {
      display: inherit;
    }
  }
`;

export const DropdownElement = styled.div`
  display: flex;

  & + & {
    margin-left: 1vw;

    @media (min-width: ${breakpoints.normal}px) {
      margin-left: 0.8vw;
    }

    @media (min-width: ${breakpoints.large}px) {
      margin-left: ${convertPxToRem(24)};
    }
  }
`;

export const MobileContainer = styled(Container)`
  max-width: 100%;
  padding: ${convertPxToRem(8)} ${convertPxToRem(16)};
`;

export const NavbarWrapper = styled.div`
  background: ${colors.green.base};
  height: 100%;
  padding: ${convertPxToRem(16)} 0 ${convertPxToRem(40)};
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  z-index: 0;
`;

export const Stamp = styled.div`
  position: absolute;
  right: -${convertPxToRem(80)};
  bottom: 0;
  color: ${transparentize(0.9, 'black')};
  z-index: -1;

  svg {
    width: ${convertPxToRem(371)};
    height: ${convertPxToRem(204)};
  }
`;

export const MobileWrapper = styled.div<{
  isOpen: boolean;
}>`
  position: relative;
  z-index: 10;

  ${({ isOpen }) =>
    isOpen &&
    css`
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;

      & + ${AppLayout} {
        position: fixed;
      }

      ${MobileContainer} {
        padding: ${convertPxToRem(16)};
      }
    `};
`;

export const MobileLayout = styled.div<{
  isOpen: boolean;
  isScrolled: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;

  background-repeat: no-repeat;
  background-position: center bottom;
  z-index: 10;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: 0.4s transform ease-out;

  ${({ isOpen }) =>
    isOpen
      ? css`
          transform: translate3d(0, 0, 0);
        `
      : css`
          transform: translate3d(-100vh, 0, 0);
        `};
`;

export const Buttons = styled.div`
  padding: 0 ${convertPxToRem(18)};
  display: flex;

  @media (min-width: ${breakpoints.xsmall}px) {
    padding-left: ${convertPxToRem(24)};
  }

  @media (min-width: ${breakpoints.small}px) {
    padding-left: ${convertPxToRem(40)};
  }

  a {
    color: white;
    display: inline-flex;
    align-items: center;
    font-weight: bold;
    margin: ${convertPxToRem(18)} 0 0;

    :first-child {
      margin-top: 0;
    }
  }

  span {
    display: inline-block;
    padding: 0 0 0 ${convertPxToRem(8)};
  }

  & + & {
    margin-top: ${convertPxToRem(16)};
  }
`;

export const List = styled.div`
  padding: ${convertPxToRem(40)} 0;
`;
