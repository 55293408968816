import React, { Fragment, PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import DropDownIcon from '@/assets/icons/arrow.svg';
import CustomLink, {
  TargetLink
} from '@/components/common/customLink/CustomLink';

import { isActualUrl, LinksList } from '../../interfaces';
import { Label, Link as LinkOpener, List } from './NavbarDropdown.styles';

type StateProps = {
  isOpen: boolean;
};

type NavbarDropdownProps = {
  pathname: string;
  onSelectItem?(): void;
};

export type Props = NavbarDropdownProps & LinksList & WithTranslation;

export class NavbarDropdown extends PureComponent<Props, StateProps> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.toggleIsOpen = this.toggleIsOpen.bind(this);
    this.dispatchSelect = this.dispatchSelect.bind(this);
  }

  toggleIsOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  dispatchSelect() {
    if (this.props.onSelectItem) {
      this.setState({
        isOpen: false
      });
      this.props.onSelectItem();
    }
  }

  render() {
    const { label, links, refs, t, pathname } = this.props;
    const { isOpen } = this.state;
    const isCurrent = (links && isActualUrl(refs, pathname)) || false;

    const isSingle = Boolean(links?.length === 1);

    return (
      <Fragment>
        {isSingle && links && links.length > 0 ? (
          <LinkOpener>
            <CustomLink
              link={links[0]}
              dataTest={'custom-link-dropdown'}
              key={links[0].label}
              id={`navbar-link-${links[0].label}`}
              data-test="link"
            >
              <Label isOpen={isOpen} current={isCurrent} data-test="label">
                {t(label)}
              </Label>
            </CustomLink>
          </LinkOpener>
        ) : (
          <Fragment>
            <LinkOpener data-test="link">
              <Label
                isOpen={isOpen}
                current={isCurrent}
                onClick={this.toggleIsOpen}
                data-test="label"
              >
                {t(label)}
                <DropDownIcon />
              </Label>
            </LinkOpener>
            {isOpen && links && (
              <List data-test="list">
                {links.map(link => (
                  <CustomLink
                    link={link}
                    key={link.label}
                    dataTest={'custom-link-dropdown'}
                    id={`navbar-link-${link.label}`}
                    target={!link.isInternal ? TargetLink.BLANK : undefined}
                  >
                    <span onClick={() => this.dispatchSelect()}>
                      {t(link.label)}
                    </span>
                  </CustomLink>
                ))}
              </List>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(NavbarDropdown);
