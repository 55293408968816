import { CityData } from '@/components/showcase/homepage/cityContext/CityContext';
import { UrlProps, URLS } from '@/constants/global';

export type LinksList = {
  label: string;
  refs: string[];
  href?: string;
  links?: UrlProps[];
};

export const NAVIGATION_LINKS: LinksList[] = [
  {
    label: 'ads',
    refs: [URLS.SEARCH.href],
    links: [URLS.SEARCH]
  },
  {
    label: 'beAlerted',
    refs: [URLS.ALERT.href],
    links: [URLS.ALERT]
  },
  {
    label: 'aboutUs',
    refs: [],
    links: [
      URLS.ABOUT,
      URLS.AGENTS,
      URLS.JOIN_US,
      { ...URLS.SMART_RECRUITERS, label: 'beAdvisor' }
    ]
  },
  {
    label: 'resources',
    refs: [],
    links: [URLS.SELLER_GUIDE, URLS.BLOG, URLS.FAQ]
  }
];

export const isActualUrl = (refs: string[], pathname: string) =>
  refs.includes(pathname);

export type NavbarProps = {
  isTransparent: boolean;
  city: CityData;
  phoneNumber: string;
  useAgentPhone: boolean;
  theme?: string;
};

export type ExtendedNavbarProps = NavbarProps & {
  isScrolled: boolean;
};

export enum NAVIGATION_POSITIONS {
  HIDDEN = 'hidden',
  PINNED = 'pinned',
  FIXED = 'fixed'
}
