import React, { FC, Fragment, PropsWithChildren } from 'react';

import { CityData } from '@/components/showcase/homepage/cityContext/CityContext';

import { AppLayout } from '../App.styles';
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';

export type MainLayoutProps = {
  isTransparent: boolean;
  city: CityData;
  phoneNumber: string;
  useAgentPhone: boolean;
  theme?: string;
};

export const MainLayout: FC<PropsWithChildren<MainLayoutProps>> = ({
  children,
  isTransparent,
  city,
  phoneNumber,
  useAgentPhone,
  theme
}) => (
  <Fragment>
    <Navbar
      city={city}
      isTransparent={isTransparent}
      phoneNumber={phoneNumber}
      theme={theme}
      useAgentPhone={useAgentPhone}
    />
    <AppLayout>{children}</AppLayout>
    <Footer />
  </Fragment>
);
