import { useRouter } from 'next/router';
import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Account from '@/assets/icons/account.svg';
import ChevronRight from '@/assets/icons/chevron_right.svg';
import Casavo from '@/assets/svg/casavo.svg';
import CustomLink, {
  TargetLink
} from '@/components/common/customLink/CustomLink';
import FixedBody from '@/components/common/fixedBody/FixedBody';
import { CityData } from '@/components/showcase/homepage/cityContext/CityContext';
import { FEATURE_FLAG_PHONE, URLS } from '@/constants/global';
import { DEFAULT_CITY } from '@/utils/city';
import { FEATURE_FLAG_STATE } from '@/utils/feature';
import { getPhoneNumber } from '@/utils/phone';
import { TypeColor } from '@/utils/prismic';
import { formatPhoneNumber } from '@proprioo/hokkaido';
import {
  Burger,
  Button,
  ButtonAppearance,
  ButtonSizes,
  ButtonTheme,
  colors,
  IconPosition
} from '@proprioo/salatim';

import { ExtendedNavbarProps, NAVIGATION_LINKS } from '../interfaces';
import {
  Buttons,
  DropdownContainer,
  Layout,
  List,
  LogoContainer,
  MobileContainer,
  MobileLayout,
  MobileWrapper,
  NavbarWrapper,
  SimpleLink
} from '../Navbar.styles';
import Phone from '../phone/Phone';
import NavbarDropdown from './dropdown/NavbarDropdown';
import { Link } from './dropdown/NavbarDropdown.styles';

export const MobileNavbar: FC<ExtendedNavbarProps> = ({
  isTransparent,
  city,
  isScrolled,
  phoneNumber,
  useAgentPhone,
  theme
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const router = useRouter();

  const toggleOpen = (state: boolean) => {
    setIsOpen(state);
  };

  const currentCity: CityData = { ...DEFAULT_CITY, ...city };
  const phone = useAgentPhone
    ? formatPhoneNumber(phoneNumber)
    : getPhoneNumber(router.pathname, currentCity);

  return (
    <MobileWrapper isOpen={isOpen} data-test="nav-mobile">
      <Layout
        isTransparent={isTransparent}
        isOpen={isOpen}
        isScrolled={isScrolled}
        theme={theme}
        data-test="layout"
      >
        <MobileContainer>
          <LogoContainer isOpen={isOpen}>
            <CustomLink link={URLS.HOMEPAGE} id={`navbar-link-homepage`}>
              <Casavo />
            </CustomLink>
          </LogoContainer>
          <DropdownContainer />
          <Burger
            data-test="burger"
            color={
              !isOpen &&
              (isScrolled || !isTransparent || theme === TypeColor.BLUE)
                ? colors.dark.base
                : 'white'
            }
            isOpen={isOpen}
            onClick={() => toggleOpen(!isOpen)}
          />
        </MobileContainer>
      </Layout>
      <MobileLayout
        isOpen={isOpen}
        isScrolled={isScrolled}
        data-test={isOpen ? 'mobile-open' : 'mobile-close'}
      >
        <NavbarWrapper>
          <Buttons>
            <Fragment>
              <CustomLink link={URLS.DASHBOARD} id="navbar-link-dashboard">
                <Button
                  label={t('dashboard')}
                  size={ButtonSizes.SMALL}
                  appearance={ButtonAppearance.SECONDARY}
                  theme={ButtonTheme.WHITE}
                  rounded={true}
                  icon={<Account />}
                />
                <span>{t('dashboard')}</span>
              </CustomLink>
            </Fragment>
          </Buttons>
          <List>
            {NAVIGATION_LINKS.map(({ href, label, links, refs }) => (
              <Fragment key={label}>
                {href ? (
                  <Link data-test="direct-link">
                    <SimpleLink
                      href={href}
                      rel="noopener"
                      key={label}
                      id={`navbar-link-${label}`}
                      onClick={() => toggleOpen(false)}
                    >
                      {t(label)}
                    </SimpleLink>
                  </Link>
                ) : (
                  <NavbarDropdown
                    pathname={router.pathname}
                    href={href}
                    label={label}
                    refs={refs}
                    links={links}
                    onSelectItem={() => toggleOpen(false)}
                    data-test="dropdown"
                  />
                )}
              </Fragment>
            ))}
          </List>
          <Buttons>
            <CustomLink
              target={TargetLink.BLANK}
              link={URLS.BOOKING}
              id="navbar-link-booking"
            >
              <Button
                label={t('freeValuation')}
                appearance={ButtonAppearance.PRIMARY}
                theme={ButtonTheme.WHITE}
                icon={<ChevronRight />}
              />
            </CustomLink>
          </Buttons>
          {FEATURE_FLAG_PHONE === FEATURE_FLAG_STATE.ACTIVATED && (
            <Buttons>
              <Phone
                data-test="phone-component"
                phone={phone}
                isVisible={true}
                theme={ButtonTheme.WHITE}
                iconPosition={IconPosition.LEFT}
              />
            </Buttons>
          )}
        </NavbarWrapper>
      </MobileLayout>
      {isOpen && <FixedBody />}
    </MobileWrapper>
  );
};

export default MobileNavbar;
