import React, { FC } from 'react';

import Breadcrumb from './Breadcrumb';
import { BreadcrumbColor, BreadcrumbElements } from './interfaces';

type CustomBreadcrumbProps = {
  slugs: BreadcrumbElements;
  color?: BreadcrumbColor;
};

const CustomBreadcrumb: FC<CustomBreadcrumbProps> = ({
  slugs,
  color = BreadcrumbColor.DARK
}) => <Breadcrumb color={color} list={slugs} />;

export default CustomBreadcrumb;
