import { UrlProps } from '@/constants/global';

export type BreadcrumbElements = UrlProps & {
  child?: BreadcrumbElements;
};

export enum BreadcrumbColor {
  WHITE = 'white',
  GREEN = 'green',
  DARK = 'dark'
}

export type BreadcrumbProps = {
  list: BreadcrumbElements;
  color: BreadcrumbColor;
};
