import React, { FC, PropsWithChildren } from 'react';

import { MainLayout } from '@/components/app/layouts/MainLayout';
import { CityData } from '@/components/showcase/homepage/cityContext/CityContext';

import { DEFAULT_CITY } from './city';

type DefaultProps = {
  isTransparent?: boolean;
  city?: CityData;
  phoneNumber?: string;
  useAgentPhone?: boolean;
  theme?: string;
};

export const DefaultLayout: FC<PropsWithChildren<DefaultProps>> = ({
  children,
  isTransparent = false,
  city = DEFAULT_CITY,
  phoneNumber = '',
  useAgentPhone = false,
  theme
}) => (
  <MainLayout
    city={city}
    isTransparent={isTransparent}
    phoneNumber={phoneNumber}
    useAgentPhone={useAgentPhone}
    theme={theme}
  >
    {children}
  </MainLayout>
);
