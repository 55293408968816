import {
  useMobileDevice,
  useSmallSmartphoneDevice,
  useSmartphoneDevice
} from '@proprioo/salatim';

// eslint-disable-next-line react-hooks/rules-of-hooks
export const isMobileDevice = () => useMobileDevice();
// eslint-disable-next-line react-hooks/rules-of-hooks
export const isSmartphoneDevice = () => useSmartphoneDevice();
// eslint-disable-next-line react-hooks/rules-of-hooks
export const isSmallSmartphoneDevice = () => useSmallSmartphoneDevice();
