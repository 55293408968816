import React, { FC } from 'react';

import { COMPANY, UrlProps, URLS } from '@/constants/global';

import { Layout } from './Breadcrumb.styles';
import BreadcrumbElement from './BreadcrumbElement';
import { BreadcrumbElements, BreadcrumbProps } from './interfaces';

export const getBreadcrumbElements = (
  slugs: UrlProps[] | string
): BreadcrumbElements => {
  let _slugs = slugs;
  let result: BreadcrumbElements = {
    ...URLS.HOMEPAGE,
    label: COMPANY
  } as BreadcrumbElements;

  if (!Array.isArray(slugs)) {
    _slugs = [{ label: slugs, href: '' }];
  }

  if (_slugs.length) {
    result = (_slugs as UrlProps[]).reduce(
      (accu: BreadcrumbElements, slugElement: UrlProps) => {
        let lastChild = accu;
        while (lastChild.child) {
          lastChild = lastChild.child;
        }
        lastChild.child = slugElement;

        return accu;
      },
      result
    );
  }

  return result;
};

const Breadcrumb: FC<BreadcrumbProps> = ({ list }) => (
  <Layout>
    <BreadcrumbElement list={list} />
  </Layout>
);

export default Breadcrumb;
