import { URLS } from '@/constants/global';
import { CityData } from '@/components/showcase/homepage/cityContext/CityContext';

export const getPhoneNumber = (path: string, city: CityData): string => {
  const { phone, buyerPhone } = city;

  const buyerUrls = [
    URLS.ALERT.href,
    URLS.SEARCH.href,
    URLS.CONFIRMATION.href,
    URLS.CONFIRMATION_SELLER.href,
    URLS.LISTING.href,
    URLS.VISIT.href
  ];

  if (buyerUrls.includes(path)) {
    return buyerPhone;
  }

  return phone;
};

export const formatInternationalNumber = (phone: string): string =>
  phone.replace(/ /gi, '').replace(/^0/, '+33');
