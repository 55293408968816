import { useRouter } from 'next/router';
import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import Account from '@/assets/icons/account.svg';
import Arrow from '@/assets/icons/arrow.svg';
import Casavo from '@/assets/svg/casavo_cloud.svg';
import CustomLink, {
  TargetLink
} from '@/components/common/customLink/CustomLink';
import { CityData } from '@/components/showcase/homepage/cityContext/CityContext';
import { FEATURE_FLAG_PHONE, URLS } from '@/constants/global';
import { DEFAULT_CITY } from '@/utils/city';
import { FEATURE_FLAG_STATE } from '@/utils/feature';
import { getPhoneNumber } from '@/utils/phone';
import {
  Button,
  ButtonAppearance,
  ButtonTheme,
  Container,
  IconPosition
} from '@proprioo/salatim';

import {
  ExtendedNavbarProps,
  isActualUrl,
  NAVIGATION_LINKS
} from '../interfaces';
import {
  DesktopLayout,
  Dropdown,
  DropdownContainer,
  DropdownElement,
  DropdownItems,
  ItemSpan,
  Label,
  LinksContainer,
  LogoContainer,
  SimpleLink
} from '../Navbar.styles';
import Phone from '../phone/Phone';

const DesktopNavbar: FC<ExtendedNavbarProps> = ({
  city,
  isScrolled,
  useAgentPhone,
  theme
}) => {
  const { t } = useTranslation();
  const router = useRouter();

  const currentCity: CityData = { ...DEFAULT_CITY, ...city };
  const phone = getPhoneNumber(router.pathname, currentCity);

  return (
    <DesktopLayout
      isTransparent={false}
      isOpen={false}
      isScrolled={isScrolled}
      theme={theme}
      data-test="nav-desktop"
    >
      <Container>
        <LogoContainer isOpen={false}>
          <CustomLink link={URLS.HOMEPAGE} id={`navbar-link-homepage`}>
            <Casavo />
          </CustomLink>
        </LogoContainer>
        <DropdownContainer>
          {NAVIGATION_LINKS.map(({ href, label, links, refs }) => {
            const isCurrent =
              (links && isActualUrl(refs, router.pathname)) || false;

            const isSingle = Boolean(links?.length === 1);

            return (
              <DropdownElement key={label} data-test="navbar-dropdown">
                {href ? (
                  <SimpleLink
                    href={href}
                    rel="noreferrer noopener"
                    id={`navbar-link-${label}`}
                  >
                    {t(label)}
                  </SimpleLink>
                ) : (
                  <Dropdown isCurrent={isCurrent} key={label}>
                    {isSingle && links && links.length > 0 ? (
                      <Label>
                        <CustomLink
                          link={links[0]}
                          dataTest="link-app"
                          key={links[0].label}
                          id={`navbar-link-${links[0].label}`}
                        >
                          {t(links[0].label)}
                        </CustomLink>
                      </Label>
                    ) : (
                      <Fragment>
                        <Label>
                          {t(label)}
                          <Arrow />
                        </Label>
                        {links && links.length > 0 && (
                          <DropdownItems>
                            {links.map(link => (
                              <CustomLink
                                link={link}
                                dataTest="link-app"
                                key={link.label}
                                id={`navbar-link-${link.label}`}
                                target={
                                  !link.isInternal
                                    ? TargetLink.BLANK
                                    : undefined
                                }
                              >
                                <ItemSpan>{t(link.label)}</ItemSpan>
                              </CustomLink>
                            ))}
                          </DropdownItems>
                        )}
                      </Fragment>
                    )}
                  </Dropdown>
                )}
              </DropdownElement>
            );
          })}
        </DropdownContainer>
        <LinksContainer>
          <CustomLink link={URLS.DASHBOARD} id="navbar-link-dashboard">
            <Button
              label={t('dashboard')}
              appearance={ButtonAppearance.SECONDARY}
              theme={ButtonTheme.BLACK}
              rounded={true}
              icon={<Account />}
            />
          </CustomLink>
          {!useAgentPhone &&
            FEATURE_FLAG_PHONE === FEATURE_FLAG_STATE.ACTIVATED && (
              <Phone
                phone={phone}
                iconPosition={IconPosition.LEFT}
                theme={ButtonTheme.BLACK}
              />
            )}
          <CustomLink
            target={TargetLink.BLANK}
            link={URLS.BOOKING}
            id="navbar-link-booking"
          >
            <Button
              label={t('freeValuation')}
              appearance={ButtonAppearance.SECONDARY}
              theme={ButtonTheme.BLACK}
            />
          </CustomLink>
        </LinksContainer>
      </Container>
    </DesktopLayout>
  );
};

export default DesktopNavbar;
