import React, {
  FC,
  Fragment,
  PropsWithChildren,
  ReactElement,
  useState
} from 'react';

import Arrow from '@/assets/icons/arrow.svg';

import { Content, Title } from './FooterLinks.styles';
import { FooterLinksProps } from './interfaces';

const FooterLinks: FC<PropsWithChildren<FooterLinksProps>> = ({
  label,
  children
}): ReactElement => {
  const [active, setActive] = useState(false);

  return (
    <Fragment>
      <Title data-test="title" open={active} onClick={() => setActive(!active)}>
        {label}
        <Arrow />
      </Title>
      <Content data-test="content" open={active}>
        {children}
      </Content>
    </Fragment>
  );
};

export default FooterLinks;
