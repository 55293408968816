import { toDate } from 'date-fns-tz';
import { endOfDay } from 'date-fns/endOfDay';
import { getHours } from 'date-fns/getHours';
import { isWeekend } from 'date-fns/isWeekend';
import { isWithinInterval } from 'date-fns/isWithinInterval';
import { startOfDay } from 'date-fns/startOfDay';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PhoneIcon from '@/assets/icons/phone.svg';
import { DEFAULT_TIMEZONE } from '@/constants/constants';
import { formatInternationalNumber } from '@/utils/phone';
import {
  Button,
  ButtonAppearance,
  ButtonTheme,
  IconPosition
} from '@proprioo/salatim';
import joursFeries from '@socialgouv/jours-feries';

type PhoneProps = {
  phone: string;
  isVisible?: boolean;
  rounded?: boolean;
  theme?: ButtonTheme;
  label?: string;
  appearance?: ButtonAppearance;
  iconPosition?: IconPosition;
};

export const isWorkingHours = (hours: number): boolean =>
  hours > 8 && hours < 19;

export const isABankHoliday = (date: Date): boolean => {
  const currentYear = new Date().getFullYear();
  const days: Date[] = Object.values(joursFeries(currentYear));

  return Boolean(
    days.filter(bankHoliday =>
      isWithinInterval(date, {
        start: new Date(startOfDay(bankHoliday)),
        end: new Date(endOfDay(bankHoliday))
      })
    ).length
  );
};

export const canWeCallProprioo = (date: Date): boolean => {
  const today = toDate(date, { timeZone: DEFAULT_TIMEZONE });
  const hours = getHours(today);
  const isBankDay = isABankHoliday(today);
  const isWeekendDay = isWeekend(today);
  const workingHours = isWorkingHours(hours);

  if (isBankDay) {
    return false;
  }

  if (isWeekendDay) {
    return false;
  }

  return workingHours;
};

const Phone: FC<PhoneProps> = ({
  phone,
  rounded = false,
  theme = ButtonTheme.BLACK,
  appearance = ButtonAppearance.SECONDARY,
  iconPosition = IconPosition.RIGHT,
  isVisible = false,
  label
}) => {
  const { t } = useTranslation();

  const isPhoneAvailable = canWeCallProprioo(new Date());
  const [visible, updateVisible] = useState<boolean>(isVisible);

  useEffect(() => {
    if (!isVisible) {
      updateVisible(isPhoneAvailable);
    }
  }, [isPhoneAvailable]);

  return visible ? (
    <a
      aria-label={t('phone')}
      data-test="phone-number"
      href={`tel:${formatInternationalNumber(phone)}`}
      rel="noreferrer noopener"
      id="navbar-link-call-us"
    >
      <Button
        label={label ? label : phone}
        appearance={appearance}
        iconPosition={iconPosition}
        theme={theme}
        rounded={rounded}
        icon={<PhoneIcon />}
      />
    </a>
  ) : (
    <Fragment />
  );
};

export default Phone;
