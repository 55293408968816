import {
  CityData,
  CityDataList
} from '@/components/showcase/homepage/cityContext/CityContext';
import {
  PHONE_NUMBER,
  PHONE_NUMBER_AIX,
  PHONE_NUMBER_AIX_BUYER,
  PHONE_NUMBER_BUYER,
  PHONE_NUMBER_LYON,
  PHONE_NUMBER_LYON_BUYER,
  PHONE_NUMBER_NANTES,
  PHONE_NUMBER_NANTES_BUYER,
  PHONE_NUMBER_NICE,
  PHONE_NUMBER_NICE_BUYER
} from '@/constants/global';

import { TITLE_DATALAYER } from './gtm';

export const DEFAULT_CITY: CityData = {
  name: 'Île-de-France',
  phone: PHONE_NUMBER,
  buyerPhone: PHONE_NUMBER_BUYER,
  title: 'punchline',
  departmentsCode: [],
  dataLayerTitle: TITLE_DATALAYER.HOMEPAGE,
  articles: [
    {
      title: 'Quel sera l’impact du covid-19 sur le marché immobilier ?',
      picture: 'covid.jpg',
      date: '24/03/2020',
      url: 'https://www.proprioo.fr/blog/quel-sera-limpact-du-covid-19-sur-le-marche-immobilier/'
    },
    {
      title: 'Que peut-on acheter à Asnières avec 500 000 €?',
      picture: 'asnieres.jpg',
      date: '06/11/2019',
      url: 'https://www.proprioo.fr/blog/que-peut-on-acheter-a-asnieres-avec-500-000e/'
    },
    {
      title: 'Tout ce qu’il faut savoir pour investir à Boulogne-Billancourt',
      picture: 'boulogne.jpg',
      date: '31/10/2019',
      url: 'https://www.proprioo.fr/blog/tout-ce-quil-faut-savoir-pour-investir-a-boulogne-billancourt/'
    }
  ],
  weArePictures: {
    background: 'paris.jpg',
    mobile: 'margaux.png',
    desktop: 'agents.png'
  },
  weAreText: 'titleWhatConsultant',
  slug: 'home-idf',
  mapConf: {
    link: '/nosannonces?locationIds=FR-IDF,FR-75056',
    position: {
      top: '28.05%',
      left: '56.46%'
    }
  }
};

export const CITY_DATA: CityDataList = {
  idf: DEFAULT_CITY,
  lyon: {
    name: 'Lyon',
    phone: PHONE_NUMBER_LYON,
    buyerPhone: PHONE_NUMBER_LYON_BUYER,
    dataLayerTitle: TITLE_DATALAYER.HOMEPAGE_LYON,
    title: 'punchlineCity',
    departmentsCode: ['69', '01', '07', '26', '42', '38', '73', '74'],
    articles: [
      {
        title: 'Propriétaires, faut-il vendre avant d’acheter ?',
        picture: 'vendreAcheter.jpg',
        date: '30/09/2019',
        url: 'https://www.proprioo.fr/blog/proprietaires-faut-il-vendre-avant-dacheter/'
      },
      {
        title: 'Les différents types de viager',
        picture: 'viager.jpg',
        date: '13/01/2020',
        url: 'https://www.proprioo.fr/blog/les-differents-types-de-viager/'
      },
      {
        title: 'Comment réussir la visite de son logement ?',
        picture: 'visite.jpg',
        date: '10/10/2019',
        url: 'https://www.proprioo.fr/blog/comment-reussir-la-visite-de-son-logement/'
      }
    ],
    weArePictures: {
      background: 'lyon.jpg',
      mobile: 'charlotte.png',
      desktop: 'agentsLyon.png'
    },
    weAreText: 'titleWhatConsultantCity',
    slug: 'immobilier-lyon',
    mapConf: {
      link: '/nosannonces?locationIds=FR-69123,FR-69',
      position: {
        top: '54.72%',
        left: '70%'
      }
    }
  },
  nice: {
    name: 'Nice',
    phone: PHONE_NUMBER_NICE,
    buyerPhone: PHONE_NUMBER_NICE_BUYER,
    dataLayerTitle: TITLE_DATALAYER.HOMEPAGE_NICE,
    title: 'punchlineCity',
    departmentsCode: ['06', '83', '05', '04'],
    articles: [
      {
        title: 'Que peut-on acheter à Nice avec un budget de 850 000 € ?',
        picture: 'nice.jpg',
        date: '07/08/2019',
        url: 'https://www.proprioo.fr/blog/acheter-a-nice-avec-850000-euros/'
      },
      {
        title: 'Propriétaires, faut-il vendre avant d’acheter ?',
        picture: 'vendreAcheter.jpg',
        date: '30/09/2019',
        url: 'https://www.proprioo.fr/blog/proprietaires-faut-il-vendre-avant-dacheter/'
      },
      {
        title: 'Les différents types de viager',
        picture: 'viager.jpg',
        date: '13/01/2020',
        url: 'https://www.proprioo.fr/blog/les-differents-types-de-viager/'
      }
    ],
    weArePictures: {
      background: 'nice.jpg',
      mobile: 'alexis.png',
      desktop: 'agentsNice.png'
    },
    weAreText: 'titleWhatConsultantCity',
    slug: 'immobilier-nice',
    mapConf: {
      link: '/nosannonces?locationIds=FR-06088,FR-06',
      position: {
        top: '79.90%',
        left: '93.42%'
      }
    }
  },
  'aix-en-provence': {
    name: 'Aix-en-Provence',
    phone: PHONE_NUMBER_AIX,
    buyerPhone: PHONE_NUMBER_AIX_BUYER,
    dataLayerTitle: TITLE_DATALAYER.HOMEPAGE_AIX,
    title: 'punchlineCity',
    departmentsCode: ['13', '84'],
    articles: [
      {
        title: 'Propriétaires, faut-il vendre avant d’acheter ?',
        picture: 'vendreAcheter.jpg',
        date: '30/09/2019',
        url: 'https://www.proprioo.fr/blog/proprietaires-faut-il-vendre-avant-dacheter/'
      },
      {
        title: 'Les différents types de viager',
        picture: 'viager.jpg',
        date: '13/01/2020',
        url: 'https://www.proprioo.fr/blog/les-differents-types-de-viager/'
      },
      {
        title: 'Comment réussir la visite de son logement ?',
        picture: 'visite.jpg',
        date: '10/10/2019',
        url: 'https://www.proprioo.fr/blog/comment-reussir-la-visite-de-son-logement/'
      }
    ],
    weArePictures: {
      background: 'aix.jpg',
      mobile: 'aurelien.png',
      desktop: 'agentsAix.png'
    },
    weAreText: 'titleWhatConsultantCity',
    slug: 'immobilier-aix-en-provence',
    mapConf: {
      link: '/nosannonces?locationIds=FR-13001,FR-13055,FR-13',
      position: {
        top: '83.33%',
        left: '70.27%'
      }
    }
  },
  nantes: {
    name: 'Nantes',
    phone: PHONE_NUMBER_NANTES,
    buyerPhone: PHONE_NUMBER_NANTES_BUYER,
    dataLayerTitle: TITLE_DATALAYER.HOMEPAGE_NANTES,
    title: 'punchlineCity',
    departmentsCode: ['44', '49', '85', '56'],
    articles: [
      {
        title: 'Propriétaires, faut-il vendre avant d’acheter ?',
        picture: 'vendreAcheter.jpg',
        date: '30/09/2019',
        url: 'https://www.proprioo.fr/blog/proprietaires-faut-il-vendre-avant-dacheter/'
      },
      {
        title: 'Les différents types de viager',
        picture: 'viager.jpg',
        date: '13/01/2020',
        url: 'https://www.proprioo.fr/blog/les-differents-types-de-viager/'
      },
      {
        title: 'Comment réussir la visite de son logement ?',
        picture: 'visite.jpg',
        date: '10/10/2019',
        url: 'https://www.proprioo.fr/blog/comment-reussir-la-visite-de-son-logement/'
      }
    ],
    weArePictures: {
      background: 'nantes.jpg',
      mobile: 'nantes_mobile.png',
      desktop: 'nantes_desktop.png'
    },
    weAreText: 'titleWhatConsultantCity',
    slug: 'immobilier-nantes',
    mapConf: {
      link: '/nosannonces?locationIds=FR-44109,FR-44',
      position: {
        top: '42.77%',
        left: '32.40%'
      }
    }
  }
};
