import styled, { css } from 'styled-components';

import { breakpoints, colors, convertPxToRem, radius } from '@proprioo/salatim';

export const Layout = styled.ul`
  position: relative;
  z-index: 0;
  margin: 0;
  padding: 0 0 ${convertPxToRem(18)};
  list-style: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: none;

  @media (min-width: ${breakpoints.base}px) {
    display: block;
  }
`;

const elementBase = css`
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  line-height: 1.3;
  transition: 0.2s opacity ease-out;
  color: ${colors.dark.base};

  @media (min-width: ${breakpoints.small}px) {
    max-width: unset;
  }

  ::before {
    content: '';
    width: ${convertPxToRem(4)};
    height: ${convertPxToRem(4)};
    border-radius: ${radius.rounded};
    background: ${colors.dark.base};
    margin: 0 ${convertPxToRem(8)};
    transform: translateY(-${convertPxToRem(2)});
    display: inline-block;
  }
`;

const ColoredElement = css`
  pointer-events: none;
  font-weight: 400;
`;

export const FakeElement = styled.span`
  ${elementBase};
  display: none;
`;

export const Element = styled.span<{
  isActive: boolean;
}>`
  ${elementBase};

  :hover {
    opacity: 0.5;
  }

  ${({ isActive }) => isActive && ColoredElement}
`;

export const ElementWrapper = styled.li`
  display: inline;

  :first-child {
    ${Element} {
      ::before {
        display: none;
      }
    }
  }
`;
