import React, { Fragment, useEffect } from 'react';

const FixedBody = () => {
  useEffect(() => {
    if (document.body) {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = '';
      };
    }
  }, []);

  return <Fragment />;
};

export default FixedBody;
