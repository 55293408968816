import styled, { css } from 'styled-components';

import { SimpleLink } from '@/components/app/navbar/Navbar.styles';
import {
  breakpoints,
  colors,
  convertPxToRem,
  fonts,
  paragraphs
} from '@proprioo/salatim';

export const Link = styled.div`
  padding: 0 ${convertPxToRem(12)} 0 ${convertPxToRem(18)};

  @media (min-width: ${breakpoints.xsmall}px) {
    padding-left: ${convertPxToRem(24)};
    padding-right: ${convertPxToRem(19)};
  }

  @media (min-width: ${breakpoints.small}px) {
    padding-left: ${convertPxToRem(40)};
    padding-right: ${convertPxToRem(36)};
  }

  & + & {
    margin-top: ${convertPxToRem(24)};
  }

  ${SimpleLink} {
    padding: 0;
  }
`;

export const Label = styled.div<{ isOpen: boolean; current?: boolean }>`
  color: white;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: ${fonts.medium};
  justify-content: space-between;

  ${({ current }) =>
    current &&
    css`
      color: ${colors.terracota.base};

      svg,
      path {
        fill: ${colors.terracota.base};
      }
    `};

  svg {
    transition: transform 0.2s;
    width: ${convertPxToRem(32)};
    height: ${convertPxToRem(32)};
    transform: rotate(90deg);

    ${({ isOpen }) =>
      isOpen &&
      css`
        transform: rotate(-90deg);
      `};
  }
`;

export const List = styled.div`
  padding: ${convertPxToRem(4)} ${convertPxToRem(18)};
  margin-top: ${convertPxToRem(8)};
  margin-bottom: ${convertPxToRem(24)};

  @media (min-width: ${breakpoints.xsmall}px) {
    padding-left: ${convertPxToRem(24)};
  }

  @media (min-width: ${breakpoints.small}px) {
    padding-left: ${convertPxToRem(40)};
  }

  a {
    ${paragraphs.body2};
    color: white;
    font-size: ${fonts.base};
    display: block;

    & + a {
      margin-top: ${convertPxToRem(18)};
    }

    &:hover {
      color: ${colors.terracota.base};
    }
  }
`;

export const Item = styled.a`
  ${paragraphs.body2};
  color: ${colors.dark.base};
  display: block;

  & + & {
    margin-top: ${convertPxToRem(18)};
  }

  &:hover {
    color: ${colors.terracota.base};
  }
`;
