import styled from 'styled-components';

import {
  breakpoints,
  colors,
  convertPxToRem,
  paragraphs
} from '@proprioo/salatim';

export const AppLayout = styled.main`
  width: 100%;
  min-height: 100vh;
  background: ${colors.background};
  color: ${colors.dark.base};
`;

export const AppWrapperLayout = styled.div`
  padding-top: ${convertPxToRem(64)};

  @media (min-width: ${breakpoints.base}px) {
    padding-top: ${convertPxToRem(96)};
  }
`;

export const ButtonLoginLayout = styled.div`
  display: none;
`;

export const GenericParagraph = styled.p`
  ${paragraphs.body1};
  margin: ${convertPxToRem(18)} 0;
  color: ${colors.dark.base};

  a {
    font-weight: bold;
    white-space: nowrap;
    color: ${colors.terracota.base80};
  }
`;
