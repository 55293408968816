import { useRouter } from 'next/router';
import React, { FC } from 'react';
import Navigation from 'react-sticky-nav';

import { URLS } from '@/constants/global';
import { isMobileDevice } from '@/utils/responsive';

import NavbarDesktop from './desktop/NavbarDesktop';
import { NavbarProps, NAVIGATION_POSITIONS } from './interfaces';
import NavbarMobile from './mobile/NavbarMobile';

const excludedScrolledUrls = [URLS.SEARCH.href];

export const isScrolledNavbar = (pathname: string, position: string) => {
  const [url] = pathname.split('?');
  const isExcludedPath = excludedScrolledUrls.includes(url);

  return (
    (position === NAVIGATION_POSITIONS.HIDDEN ||
      position === NAVIGATION_POSITIONS.PINNED) &&
    !isExcludedPath
  );
};

const Navbar: FC<NavbarProps> = ({
  isTransparent,
  city,
  phoneNumber,
  useAgentPhone,
  theme
}) => {
  const isMobile = isMobileDevice();
  const { pathname } = useRouter();

  return (
    <Navigation
      render={({ position, ref }) => {
        const isScrolled = isScrolledNavbar(pathname, position);
        return (
          <div ref={ref}>
            {isMobile ? (
              <NavbarMobile
                city={city}
                isTransparent={isTransparent}
                isScrolled={isScrolled}
                phoneNumber={phoneNumber}
                useAgentPhone={useAgentPhone}
                theme={theme}
              />
            ) : (
              <NavbarDesktop
                city={city}
                isTransparent={isTransparent}
                isScrolled={isScrolled}
                phoneNumber={phoneNumber}
                useAgentPhone={useAgentPhone}
                theme={theme}
              />
            )}
          </div>
        );
      }}
    >
      {}
    </Navigation>
  );
};

export default Navbar;
