import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import CustomLink from '../customLink/CustomLink';
import { Element, ElementWrapper, FakeElement } from './Breadcrumb.styles';
import { BreadcrumbElements } from './interfaces';

type BreadcrumbElementProps = {
  list: BreadcrumbElements;
  current?: BreadcrumbElements;
};

const BreadcrumbElement: FC<BreadcrumbElementProps> = ({
  list,
  current = list
}) => {
  const { label, href, as, isInternal, child } = current;
  const { t } = useTranslation();

  const isLast = !child;

  return (
    <Fragment>
      <ElementWrapper data-test="breadcrumb-el-wrapper">
        {href ? (
          <CustomLink
            link={{ href, as, isInternal, label: `breadcrumbs-${label}` }}
          >
            <Element isActive={isLast}>{label}</Element>
          </CustomLink>
        ) : (
          <Element isActive={isLast} aria-label={label}>
            {label}
          </Element>
        )}
        <FakeElement>{t('points')}</FakeElement>
      </ElementWrapper>
      {child && <BreadcrumbElement list={list} current={child} />}
    </Fragment>
  );
};

export default BreadcrumbElement;
