import { transparentize } from 'polished';
import styled from 'styled-components';

import { Grid } from '@/utils/styles';
import {
  breakpoints,
  colors,
  convertPxToRem,
  paragraphs,
  radius
} from '@proprioo/salatim';

export const Links = styled.footer`
  background: ${colors.green.base};
  padding: ${convertPxToRem(32)} 0 ${convertPxToRem(100)};

  @media (min-width: ${breakpoints.small}px) {
    padding-top: ${convertPxToRem(56)};
    padding-bottom: ${convertPxToRem(100)};
  }

  @media (min-width: ${breakpoints.base}px) {
    padding-bottom: ${convertPxToRem(56)};
  }
`;

export const Content = styled.div`
  ${Grid};
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${convertPxToRem(8)} 0 0;

  @media (min-width: ${breakpoints.small}px) {
    margin-top: ${convertPxToRem(24)};
  }
`;

export const LogoSocials = styled.div`
  grid-column: 1 / -1;

  @media (min-width: ${breakpoints.normal}px) {
    grid-column: 1 / 4;
  }
`;

export const LogoWrapper = styled.div`
  a {
    color: white;
  }

  svg {
    height: ${convertPxToRem(32)};
    width: ${convertPxToRem(178)};
  }
`;

export const LinksCategories = styled.div`
  grid-column: 1 / -1;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: ${convertPxToRem(16)};

  @media (min-width: ${breakpoints.xsmall}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${convertPxToRem(32)};
  }

  @media (min-width: ${breakpoints.medium}px) {
    grid-template-columns: repeat(4, 1fr);
    order: 3;
  }

  @media (min-width: ${breakpoints.normal}px) {
    grid-template-columns: repeat(4, 1fr);
    grid-column: 4 / 12;
    grid-row: 1 / 3;
  }
`;

export const LinksList = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${convertPxToRem(24)} 0 0;

  @media (min-width: ${breakpoints.normal}px) {
    padding-top: 0;
  }

  > a {
    ${paragraphs.body1};
    font-weight: bold;
    color: white;
    transition: 0.2s opacity ease-out;

    :hover {
      opacity: 0.8;
    }
  }
`;

export const Socials = styled.div`
  a {
    color: ${colors.dark.base};
  }
`;

export const Round = styled.a`
  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};
  background: white;
  color: ${colors.dark.base};
  border-radius: ${radius.rounded};
  margin: 0 0 0 ${convertPxToRem(8)};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s background ease-out, 0.2s color ease-out;

  svg {
    width: ${convertPxToRem(24)};
    height: ${convertPxToRem(24)};
  }

  :hover {
    background: ${transparentize(0.8, 'white')};
    color: white;
  }

  :first-child {
    margin: 0;
  }
`;

export const SocialWrapper = styled.div`
  grid-column: 1 / -1;
  flex: 1;
  display: flex;
  margin: ${convertPxToRem(32)} 0 0;

  @media (min-width: ${breakpoints.medium}px) {
    margin-top: ${convertPxToRem(24)};
    order: 2;
  }

  @media (min-width: ${breakpoints.normal}px) {
    grid-column: 1 / 4;
    grid-row: 2 / 3;
  }
`;

export const CookieButton = styled.button`
  color: currentColor;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
`;

export const Legal = styled.div`
  ${paragraphs.body1};
  display: flex;
  flex-direction: column;
  color: white;

  @media (min-width: ${breakpoints.small}px) {
    flex-direction: row;
  }

  span {
    padding: 0 ${convertPxToRem(8)};
  }

  ${CookieButton},
  a {
    ${paragraphs.body1};
    cursor: pointer;
    color: white;
    margin: ${convertPxToRem(8)} 0 0;
    transition: 0.2s opacity ease-out;
    text-align: left;

    @media (min-width: ${breakpoints.small}px) {
      margin-left: ${convertPxToRem(24)};
    }

    :hover {
      opacity: 0.5;
    }

    :first-child {
      margin-left: 0;
    }
  }
`;
