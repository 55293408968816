import styled, { css } from 'styled-components';

import {
  breakpoints,
  colors,
  convertPxToRem,
  fonts,
  paragraphs
} from '@proprioo/salatim';

import { TitleProps } from './interfaces';

export const Title = styled.div<TitleProps>`
  font-size: ${fonts.medium};
  font-weight: bold;
  line-height: ${convertPxToRem(24)};
  color: ${colors.pink.base};
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${breakpoints.medium}px) {
    pointer-events: none;
  }

  svg {
    width: ${convertPxToRem(32)};
    height: ${convertPxToRem(32)};
    transform: rotate(90deg);
    transition: 0.2s ease-out transform;

    @media (min-width: ${breakpoints.medium}px) {
      display: none;
    }
  }

  ${({ open }) =>
    open &&
    css`
      svg {
        transform: rotate(-90deg);
      }
    `}
`;

export const Content = styled.div<TitleProps>`
  display: none;
  flex-direction: column;

  ${({ open }) =>
    open &&
    css`
      display: flex;
    `}

  @media (min-width: ${breakpoints.medium}px) {
    display: flex;
  }

  a {
    ${paragraphs.body1}
    margin-top: ${convertPxToRem(8)};
    color: white;
    transition: 0.2s opacity ease-out;

    :hover {
      opacity: 0.5;
    }
  }
`;
